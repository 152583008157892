.tag {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  padding: 0 8px;
  border: 1px solid #c94141;
  border-radius: 2em;
  color: #c94141;
}

.tag__icon {
  position: relative;
  top: 2px;
  margin-left: 4px;
  font-size: .75em;
  fill: #c94141;
}