.feedbackPanel {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 16px;
  box-shadow: 0px 1px 8px 0.2px rgba(0, 0, 0, 0.16);

  box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.2);
}

.feedbackPanel__inner {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-left: 80px;
  color: #000;
}

.feedbackPanel__inner:before {
  content: '';
  position: absolute;
  left: 0;
  width: 50px;
  height: 50px;
  background: url(/public/img/icons/envelope.png) no-repeat;
  background-size: contain;
}

.feedbackPanel__title {
  margin-bottom: 4px;
  font-size: 18px;
  line-height: 1.125;
}

.feedbackPanel__text {
  font-size: 12px;
  line-height: 1.125;
  color: #9e2727;
}