.levelPlan {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0 16px;
    user-select: none;
}

.schemeLevels .levelPlan {
    transition: all .3s linear;
}

.levelPlanDetail {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform-origin: 0 0;
}

.levelPlanScheme {
    position: relative;
    margin: 0 auto;
    will-change: transform;
    touch-action: none;
    user-select: none;
    /*transform-origin: 50% 50%;*/
}


.levelPlanScheme__inner {
    position: relative;
    top: 0;
    left: 0;
    
    transform-origin: 0 0;
    overflow: hidden;
   
}

.levelPlanScheme img {
    max-width: 100%;
}


.levelPlanZoom {
    position: absolute;
    left: 50%;
    top: 32px;
    transform: translateX(-50%);
    height: 42px;
    padding: 8px;
    display: flex;
    border-radius: 2em;
    background: rgba(228, 228, 228, 0.35);
}

.levelPlanZoom__add,
.levelPlanZoom__sub {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: rgba(228, 228, 228, 1);
    font-size: 14px;
    cursor: pointer;
    fill: #222;
}

.levelPlanZoom__sub {
    margin-right: 16px;
}

.levelPlanZoom__add.end,
.levelPlanZoom__sub.end {
    fill: #999;
    opacity: .5;
}
