.loginPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
}

.loginPanel {
    width: 100%;
    max-width: 400px;
    padding: 24px;
}

.loginPageBack {
    position: absolute;
    top: 24px;
    left: 24px;
    font-size: 24px;
    stroke: #000;
}

.loginPageClose {
    position: absolute;
    top: 24px;
    right: 24px;
    font-size: 24px;
}
