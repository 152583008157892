.readonlyField {
    padding: 0 0 16px;
    font-size: 16px;
    font-weight: 700;
}

.readonlyField:last-child {
    border-bottom: 0;
}

.readonlyField label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
}

.readonlyField.light label {
    color: #9c9c9c;
}

.readonlyField.red .value {
    color: #c94141;
}