.tenantItem {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 16px;
  overflow: hidden;
  color: #000;
  background: #fff;
  box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.12);
}

.tenantItem__logo {
  display: block;
  margin-right: 16px;
  width: 48px;
  height: 32px;
  object-fit: contain;
}

.tenantItem__body {
  
}

.tenantItem__title {
  font-size: 14px;
  line-height: 1.25;
}
