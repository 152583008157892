.requestStatus {
  display: inline-flex;
  align-items: center;
}

.requestStatus.vertical {
  display: block;
  width: 64px;
  text-align: center;
}

.requestStatus.vertical .requestStatusTitle {
  margin-top: 4px;
  font-size: 10px;
}

.requestStatus.vertical .requestStatusIcon {
  margin-right: auto;
}

.requestStatus.open {
  fill: #00B1F4;
  stroke: #00B1F4;
}

.requestStatus.inProgress {
  fill: #FEA83C;
  stroke: #FEA83C;
}

.requestStatus.completed {
  fill: #7CCF78;
  stroke: #7CCF78;
}

.requestStatus.declined {
  fill: #ccc;
  stroke: #ccc;
}

.requestStatusIcon {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 32px;
  height: 32px;
  font-size: 32px;
  margin-right: 8px;
}

.requestStatusIcon .icon {
  position: relative;
  z-index: 2;
}

.requestStatusTitle {
  font-size: 14px;
  white-space: nowrap;
}