.fieldset {
  margin-top: 24px;
  padding: 24px 16px;
}

.fieldset_withShadow {
  box-shadow: 0px 0 8px 0.2px rgba(0, 0, 0, 0.16);
}

.fieldsetTitle {
  position: relative;
  top: -1px;
  margin-bottom: 16px;
  font-size: 20px;
}
.fieldsetTitle_size_lg {
  top: -2px;
  font-size: 24px;
}

.fieldset .field {
  margin-bottom: 16px;
}
.fieldset .field:last-child {
  margin-bottom: 0;
}
