.schemePlace {
    position: absolute;
    z-index: 100;
}

.schemePlace__inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.schemePlace__number {
    padding: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    color: #333;
}

.schemePlace__svg {
    fill: rgba(100, 100, 100, 0.25);
    transition: all .3s linear;
}

.freeToBuy .schemePlace__svg,
.freeToRent .schemePlace__svg,
.free .schemePlace__svg {
    fill: rgba(145, 203, 138, 0.5);
}

.booked .schemePlace__svg {
    fill: rgba(202, 95, 81, 0.75);
}

.common .schemePlace__svg {
    fill: #A3BDC5;
}

.isUsed .schemePlace__svg {
    fill: rgba(255, 255, 255, 1)
}

.busy .schemePlace__svg {
    fill: rgba(202, 95, 81, 0.5);
}

.isSelected, .isCurrent {
    z-index: 999;
}

.isSelected .schemePlace__svg, 
.isCurrent .schemePlace__svg {
    fill: rgba(202, 95, 81, 0.85);
}

.isSelected.freeToBuy .schemePlace__svg,
.isSelected.freeToRent .schemePlace__svg,
.isSelected.free .schemePlace__svg {
    fill: rgba(145, 203, 138, 1);
}

.levelPlan.selected .schemePlace {
    filter: grayscale(.3);
}

.isSelected, 
.isCurrent {
    opacity: 1 !important;
    filter: none !important;
}

.isSelected.isUsed .schemePlaceInfo,
.isCurrent.isUsed .schemePlaceInfo {
    filter: brightness(0) invert(1);
}

@keyframes pulseTmp {
    0% {
        transform: scale(1.05);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
        filter: drop-shadow(0 0 0 rgba(0, 255, 0, .7));
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        filter: drop-shadow(0 0 100px rgba(0, 255, 0, 0));
    }

    100% {
        transform: scale(1.05);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        filter: drop-shadow(0 0 0 rgba(0, 255, 0, 0));
    }
}