.toggle {
  position: relative;
  display: flex;
  padding: 3px;
  border-radius: 2em;
  background: #f5f5f5;
}

.toggle_state1 .toggle__btn {
  
}

.toggle_state1 .toggle__item:nth-child(2) {
  fill: #000;
}

.toggle_state2 .toggle__btn {
  transform: translateX(34px);
}

.toggle_state2 .toggle__item:nth-child(3) {
  fill: #000;
}

.toggle__item {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
  font-size: 16px;
  width: 30px;
  height: 30px;
  border-radius: 2em;
  fill: #ccc;
  cursor: pointer;
  transition: all .15s ease;
}

.toggle__item:last-child {
  margin-right: 0;
}

.toggle__btn {
  position: absolute;
  left: 3px;
  width: 30px;
  height: 30px;
  border-radius: 2em;
  background: #fff;
  transition: all .15s ease;
}
