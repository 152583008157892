.detailText {
    padding: 16px 16px;
}

.detailText img {
    max-width: 100%;
}


.detailText ul,
.detailText ol {
    padding: 0 0 0 1em;
    margin: 0;
}

.detailText li {
    margin-bottom: .5em;
}