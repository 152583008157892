.addToHomeScreen {
  position: fixed;
  bottom: 80px;
  left: 20px;
  right: 20px;
  padding: 16px;
  border-radius: 8px;
  background: rgba(40, 40, 40, 0.9);

  display: flex;
  align-items: center;

  color: #fff;
  fill: #fff;
}

.addToHomeScreen:after {
  content: '';
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);

  width: 0; 
  height: 0; 
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  
  border-top: 12px solid rgba(40, 40, 40, 0.9);
}

.addToHomeScreen__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  min-width: 40px;
  height: 40px;
  margin-right: 16px;
  border: 2px solid #fff;
  border-radius: 8px;
  cursor: pointer;
}

.addToHomeScreen__main {
  font-size: 14px;
}

.addToHomeScreen__main .icon {
  position: relative;
  top: 4px;
  font-size: 20px;
}

.addToHomeScreen__close {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #fff;
  border: 1px solid #bc1212;
  background: #bc1212;
  cursor: pointer;
}