.page404 {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.block404 {
    width: 100%;
    max-width: 350px;
    padding: 35px 50px;
}

.block404__code {
    font-size: 86px;
    font-weight: 700;
    line-height: 1;
}

.block404__title {
    margin-bottom: 16px;
    font-size: 22px;
}

.block404__link {

}