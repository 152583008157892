.myPassGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
}


.myPassLink {
    display: flex;
    align-items: center;
    padding: 12px 12px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.12);
    color: #383838;
}

.myPassLink__title {
    display: block;
    margin-right: 14px;
    flex: 1;
    text-align: left;
    font-size: 14px;
    line-height: 1.125;
}

.myPassLink__icon {
    height: 20px;
    object-fit: contain;
}


.myPassSlick {
    margin: 0 -8px;
}

.myPassSlick .slick-slide {
    padding: 8px;
}



@media (min-width: 375px) {
    .myPassLink {
        padding: 16px;
    }

    .myPassLink__title {
        margin-right: 12px;
        font-size: 17px;
    }

    .myPassLink__icon {
        height: 20px;
    }
}