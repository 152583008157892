.contactItem {
  margin: 0 -16px 16px;
  box-shadow: 0px 1px 8px 0.2px rgba(0, 0, 0, 0.16);
}

.contactItemLink {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  color: #000;
}

.contactItemLink__icon {
  margin-right: 8px;
  font-size: 32px;
}
.contactItemLink__icon.telegram {
  padding: 1px;
  font-size: 30px;
}

.contactItemLink__title {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 1.25;
  font-weight: 600;
}

.contactItemLink__value {

}
