.formSuccess {
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 150px;
  padding: 32px 16px 0;
  border-radius: 32px 32px 0 0;
  background: #fff;

  transition: all .3s linear;
  transform: translate3d(0, 120%, 10px);
}

.form_success .formSuccess {
  transform: translate3d(0, 0, 10px);
}


.formSucess__icon {
  position: absolute;
  top: 0;
  left: 50%;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background: #7CCF78;
  border: 4px solid #fff;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  fill: #fff;
}

.formSucess__title {
  margin-bottom: 8px;
  text-align: center;
  font-size: 24px;
}

.formSucess__text {
  text-align: center;
  font-size: 15px;
}
.formSucess__text .mark {
  color: #c94141;
}

.formSucess__footer {
  display: flex;
  justify-content: center;
  margin: 16px 0;
}