.textField {}

input {
  font-size: 16px;
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  border-color: #bebebe;
  border-radius: 8px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;

  font-size: 1em;
  line-height: 1.125; 
}

input:disabled {
  opacity: .7;
}

input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 300;
  color: #949494;
}
