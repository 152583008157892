.detailHeader {
    
}

.detailHeaderSlick {
    line-height: 0;
}
.detailHeaderSlick .slick-dots {
    bottom: 5px;
}
.detailHeaderSlick .slick-dots li button:before {
    color: #fff;
}
.detailHeaderSlick .slick-dots li.slick-active button:before {
    color: #fff;
}

.detailHeader__pic {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 50%;
}
.detailHeader__picImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.detailHeaderBody {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 80px;
    padding: 8px 16px 8px 16px;
    background: #737373;
    color: #fff;
}
.hasLogo .detailHeaderBody {
    padding-left: 112px;
}
.detailHeaderBody__logo {
    position: absolute;
    top: 5px;
    left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #fff;
}
.detailHeaderBody__logo img {
    width: 75%;
    height: 75%;
    object-fit: contain;
}
.detailHeader.hasImages .detailHeaderBody__logo {
    top: -40px;
    width: 80px;
    height: 80px;
}
.detailHeaderBody__title {
    margin-bottom: 2px;
    font-size: 18px;
    font-weight: 600;
}
.detailHeaderBody__property {
    display: flex;
    margin-bottom: 2px;
    font-size: 13px;
}
.detailHeaderBody__property .icon {
    position: relative;
    top: 0;
    margin-right: 4px;
    font-size: 18px;
    fill: #fff;
}
