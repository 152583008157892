.parkingFreePassPanelWrap {}


.parkingFreePassPanel {
  position: relative;
  min-height: 124px;
  padding: 20px 16px;
  background: #fff;
  box-shadow: 0px 1px 8px 0.2px rgba(0, 0, 0, 0.16);

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.parkingFreePassPanel__title {
  width: 100%;
  margin-bottom: 12px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.125;
  color: #9e2727;
}

.parkingFreePassPanel__inner {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0px 1px 8px 0.2px rgba(0, 0, 0, 0.12);
}

.parkingFreePassPanelLink {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-left: 80px;
}

.parkingFreePassPanelLink__icon {
  position: absolute;
  left: 0;
  width: 60px;
  height: 60px;
  fill: #000;
}

.parkingFreePassPanelLink:before {
  content: '';

}

.parkingFreePassPanelLink__title {
  margin-bottom: 4px;
  font-size: 18px;
  line-height: 1.125;
  color: #9e2727;
}

.parkingFreePassPanelLink__text {
  font-size: 12px;
  line-height: 1.125;
}

.parkingFreePassPanelLink__text b {
  color: #9e2727;
}





.parkingPassPanel__inner {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0px 1px 8px 0.2px rgba(0, 0, 0, 0.12);
}

.parkingPassPanelLink {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-left: 80px;
  color: #000;

  padding: 16px 16px 16px 80px;
  border-radius: 16px;
  box-shadow: 0px 1px 8px 0.2px rgba(0, 0, 0, 0.12);
}

.parkingPassPanelLink.loading .loaderWrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 12px;
  background: rgba(0, 0, 0, .25);
}

.parkingPassPanelLink__icon {
  position: absolute;
  left: 8px;
  font-size: 60px;
  fill: #808080;
}

.parkingPassPanelLink__title {
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 1.125;
  color: #9e2727;
}

.parkingPassPanelLink__text {
  font-size: 12px;
  line-height: 1.125;
  min-height: 30px;
}

.parkingPassPanelLink__text b {
  color: #9e2727;
}

.parkingPassPanelLink__text > div {
  margin-bottom: 2px;
}