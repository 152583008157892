.panel {
  position: relative;
  padding: 24px 0;
}

.panel_with_shadow:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: -20px;
  right: -20px;
  height: 100px;
  box-shadow: 0px 16px 8px -16px rgba(0, 0, 0, 0.2);
}

.panelTitle {
  position: relative;
  top: -1px;
  margin-bottom: 16px;
  padding: 0 16px;
  font-size: 20px;
}

.panelTitle_size_lg {
  top: -2px;
  font-size: 24px;
}

.panelBody {
  padding: 0 16px;
}