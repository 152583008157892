.litItem {
    display: flex;
    background: #fff;
    border-bottom: 0.5px solid #aeaeae;
    height: 60px;
    color: #383838;
}

.litItem__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
}
.litItem__icon img {
    width: 24px;
    height: 24px;
    object-fit: contain;
}


.litItem__body {
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 12px 8px;
}


.litItem__name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;
}

.litItem__icon2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
}

.litItem__icon2 .icon {
    width: 16px;
    height: 16px;
    stroke: #a7a7a7;
}

.logout {
    margin: 24px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 30px;
    border-radius: 8px;
    color: #c94141;
    fill: #c94141;
    border: 1px dashed #c94141;
    cursor: pointer;
    font-weight: 600;
}

.logout .icon {
    margin-right: 8px;
}
