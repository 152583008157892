.page {
  /*position: absolute;
  width: 100vw;*/
  transition: all .3s ease;
}

.page.dark {
  background: #282828;
}

.app_showDrawer .page {
  transform: translate(270px, 0);
}

.pageBody {
  min-height: calc(100vh - 60px);
  overflow-x: hidden;
}

.page_withHeader .pageBody {
  min-height: calc(100vh - 240px);
  padding-bottom: 80px;
}

.page_withNav .pageBody {
  padding-bottom: 60px;
}

.loginPage .pageBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 0;
}


.pageLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh + 60px);
  background: rgba(0, 0, 0, .5);

  display: flex;
  align-items: center;
  justify-content: center;
}

.pageLoader .loaderWrap {
  width: 100%;
  top: -30px;
}