.topBarSelect {
  position: relative;
}

.topBarSelect__main {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 1.125;
  padding: 8px 10px 8px 12px;
  border-radius: 8px;
  border: 1px solid #555;
  cursor: pointer;
  overflow: hidden;
  min-width: 168px;
  min-height: 34px;
  background: #000;
  user-select: none;
}

.topBarSelect__main .icon {
  margin-left: 4px;
  font-size: 12px;
  stroke: #fff;
  transform: rotate(90deg);
  transform-origin: 50% 50%;
}

.topBarSelect__dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1px 8px 0.2px rgba(0, 0, 0, 0.16);
  transform: translateY(-100%);
  opacity: 0;
  visibility: hidden;
  transition: transform .2s linear 0s, opacity .15s linear .05s;
}
.topBarSelect.active .topBarSelect__dropdown {
  transform: translateY(8px);
  opacity: 1;
  visibility: visible;
}

.topBarSelect__dropdown .item {
  display: block;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 1.125;
  color: #000;
  border-bottom: 1px solid #eaeaea;
}
.topBarSelect__dropdown .item.active {
  color: #c94141;
}

.topBarSelect__dropdown .item:first-child {
  border-radius: 8px 8px 0 0;
}
.topBarSelect__dropdown .item:last-child {
  border-radius: 0 0 8px 8px;
  border-bottom: 0;
}