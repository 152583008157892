.textField {
  
}

textarea {
  font-size: 16px;;
  width: 100%;
  min-height: 150px;
  padding: 8px 8px;
  border-color: #bebebe;
  border-radius: 8px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
}

textarea:disabled {
  opacity: .7;
}