.userTop {
  position: relative;
  cursor: pointer;
}

.userTop .icon {
  display: block;
  width: 28px !important;
  height: 28px !important;
  fill: #959595;
}

.userTopAvatar {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: url(/public/img/icons/userTop.png) 50% 50% no-repeat;
  background-size: contain;
}
