.popup {
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 150px;
  padding: 16px 24px 0;
  border-radius: 32px 32px 0 0;
  background: #fff;
  box-shadow: 0 0 10px rgba(118, 117, 113, 0.3);

  transition: all .3s linear;
  transform: translate3d(0, 140%, 10px);

  will-change: transform;
  touch-action: none;
  user-select: none;
}

.app_isStandalone .popup {
  bottom: -12px;
}

.popup.active {
  transform: translate3d(0, 0, 10px);
}


.popupClose {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 12px;
  fill: #767571;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #f7f4ef;
}

.popupTitle {
  padding-right: 24px;
  font-size: 20px;
}

.popupBody {
  padding: 16px 0;
}