.fixedBottomPanel {
  position: fixed;
  z-index: 2;
  bottom: 0;
  bottom: env(safe-area-inset-bottom);
  left: 0;
  width: 100%;
  padding: 16px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, .1);
  background-image: linear-gradient(to bottom, transparent, #fff 30%);
  transform: translateZ(1000px);
}


.fixedBottomPanel.withNav {
  margin-bottom: 76px;
}