.searchPage {
    position: fixed;
    z-index: -1;
    top: 60px;
    left: 0;
    right: 0;
    opacity: 0;
    height: 100vh;
    background: #282828;
    transition: all .25s ease;
}

body.showSearchPage {
    height: 100vh;
    overflow: hidden;
}

.showSearchPage .searchPage {
    z-index: 9999;
    opacity: 1;
    transform: translateZ(10px);
}

.searchPageLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.searchPageLoader .loaderWrap {
    width: 100%;
}

.searchPageResults {
    height: calc(100vh - 120px);
    padding: 16px 16px;
    overflow-y: scroll;
}

.searchPageResult {
    margin-bottom: 32px;
    border-radius: 16px;
    color: #fff;
    background: #202020;
}

.searchPageResult__header {
    padding: 12px 16px 12px;
}

.searchPageResultTitle {
    font-size: 16px;
    font-weight: 600;
}

.searchPageResultItems {
    margin: 0 -8px;
    padding: 0 16px 24px;
}

.searchPageResultItem {
    display: flex;
    align-items: center;
    padding: 0 4px;
    min-height: 36px;
    border-radius: 16px;
    margin-bottom: 16px;
    cursor: pointer;
    color: #fff;
}

.searchPageResultItem:last-child {
    margin-bottom: 0;
}

.searchPageResultItem:hover,
.searchPageResultItem:active {
    background: #242424;
}

.searchPageResultItem__icon {
    margin-right: 12px;
    width: 36px;
    height: 36px;
    padding: 4px;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
}

.searchPageResultItem__icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.searchPageResultItem__image {
    margin-right: 12px;
    width: 36px;
    height: 36px;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
}

.searchPageResultItem__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.searchPageResultItem__name {}

.searchPageResultsEmpty {
    text-align: center;
    color: #fff;
    padding: 32px 16px;
}
