.tabs {
  display: inline-flex;
  justify-content: center;
  padding: 3px;
  background: #f5f5f5;
  border-radius: 2em;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.125;
}

.tabs_fit {
  width: 100%;
  justify-content: space-between;
}
.tabs_fit .tabsTab {
  flex: 1;
  text-align: center;
}

.tabsTab {
  padding: 6px 12px 7px;
  border-right: 0;
  border-radius: 2em;
  color: #999;
  cursor: pointer;
}

.tabsTab.active {
  background: #fff;
  color: #000;
}
