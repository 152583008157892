.drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 270px;
  height: 100vh;
  transform: translateX(-100%);
  background: #282828;
  transition: all .3s ease;
}

.app_showDrawer .drawer {
  transform: translateX(0);
}

.drawerLogo {
  margin: 16px auto 32px;
  width: 186px;
  height: 110px;
  background: url(/public/img/logo3000_g.png) 50% 50% no-repeat;
  background-size: contain;
}

.drawerNav {
  padding: 0 16px
}

.drawerNav__item {
  display: flex;
  text-align: left;
  text-decoration: none;
  margin-bottom: 4px;
  padding: 8px 16px 9px;
  border-radius: 2em;
}

.drawerNav__itemIcon {
  display: block;
  margin: 0 auto 5px;
  width: 28px;
  height: 28px;
  object-fit: contain;
}

.drawerNav__itemTitle {
  display: block;
  font-size: 18px;
  line-height: 1.125;
  color: #949494;
}



.drawerNav__item.active .drawerNav__itemIcon {
  fill: #bc1212;
  filter: invert(13%) sepia(94%) saturate(4186%) hue-rotate(356deg) brightness(96%) contrast(96%);
}
.drawerNav__item.active {
  background: #1a1a1a;
}
.drawerNav__item.active .drawerNav__itemTitle {
  color: #fff;
}
