.newsListItem {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  overflow: hidden;
  padding: 4px;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
}

.newsListItem__image {
  margin-right: 16px;
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
}


.newsListItem__title {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 18px;
  color: #9e2727;
}
