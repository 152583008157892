.scheme {
    position: relative;
}

.scheme__title {
    margin-bottom: -40px;
    padding: 56px 16px 0;
    text-align: center;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}