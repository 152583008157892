.form {}

.formText {
    margin: 16px auto 16px;
    max-width: 290px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
}


.formHeader {
    text-align: center;
}

.formHeaderTitle {
    font-size: 20px;
    font-weight: 600;
    color: #000;
}

.formHeaderSubtitle {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #676767;
}


.formFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    min-height: 80px;
    background: #fdfdfd;
    /*border-top: 1px solid #efefef;*/
}

.formPrivacy {
    padding: 0 16px;
    font-size: 12px;
    font-weight: 700;
    color: #676767;
}
