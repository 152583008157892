.readonlyFieldset {
    box-shadow: 0px 0 8px 0.2px rgba(0, 0, 0, 0.16);
}

.readonlyFieldset .readonlyField {
    padding: 16px 16px;
    font-size: 16px;
    font-weight: 700;
    color: #858585;
    color: #000;
    border-bottom: 1px solid #d7d7d7;
}