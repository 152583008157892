.loginForm .formFooter {
  display: block;
  border: 0;
  height: auto;
}

.loginForm .formFooter .btn {
  width: 100%;
}

.altLogin {
  text-align: center;
}

.altLoginOr {
  position: relative;
  margin: 16px auto 18px;
  padding: 0 16px;
  width: max-content;
  color: #676767;
}

.altLoginOr:after, 
.altLoginOr:before {
  background-color: #676767;
  content: "";
  height: 1px;
  position: absolute;
  top: 10px;
  width: 64px;
}
.altLoginOr:before {
  left: -64px;
}
.altLoginOr:after {
  right: -64px;
}

.altLoginLink {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  font-weight: 600;
  padding: 8px 12px;
  border: 1px dashed #c94141;
  border-radius: 8px;
}

.altLoginLink .icon {
  margin-right: 8px;
}

.altLoginLink.yandex {
  color: #fff;
  border-color: #000;
  background: #000;
}

.altLoginLink.mail {
  color: #fff;
  border-color: #015FF9;
  background: #015FF9;
}

.altLoginLink.vk {
  color: #fff;
  fill: #fff;
  border-color: #0277FF;
  background: #0277FF;
}