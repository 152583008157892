.coupon {
    position: relative;
    overflow: hidden;
    margin-bottom: 16px;
    display: flex;
}
.coupon:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 16px;
    border: 1px solid #e1e1e1;
}
.coupon:after {
    content: '';
    position: absolute;
    z-index: 2;
    right: -1px;
    top: 50%;
    transform: translate(50%, -50%);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #d0d0d0;
}

.coupon__main {
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 16px;
}

.coupon__aside {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    min-width: 136px;
    max-width: 136px;
    padding: 16px 32px 16px 24px;
    border-left: 1px dashed #d0d0d0;
}

.coupon__text {
    font-size: 12px;
    line-height: 1.125;
}

.coupon__title {
    margin: 0 -16px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.125;
}

.coupon__logo {
    margin-bottom: 4p;
}
.coupon__logo img {
    width: 100%;
    object-fit: contain;
}

.couponDiscount {
    display: flex;
    margin-bottom: 16px;
}
.couponDiscount__item {
    margin-right: 8px;
    text-align: center;
}
.couponDiscount__item:last-child {
    margin-right: 0;
}
.couponDiscount__itemVal {
    margin: 0 auto 4px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.125;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;

    border-radius: 50%;
    background: #c94141;
    color: #fff;
}
.couponDiscount__itemLbl {
    font-size: 10px;
    font-weight: 700;
    line-height: 1.125;
}
