.instruction {
    padding: 24px;
}


.instructionStep {
    margin-bottom: 32px;
}

.instructionStep:last-child {
    margin-bottom: 0;
}


.instructionStep__text {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
}

.instructionStep__text .icon {
    position: relative;
    top: 4px;
    font-size: 20px;
}


.instructionStep__pic img {
    max-width: 100%;
}