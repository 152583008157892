.searchTop {
    position: absolute;
    bottom: 14px;
    left: 0;
    right: 0;
    background: transparent;
    transition: all .3s ease;

    display: flex;
    align-items: center;
    padding: 0 16px;
}

.searchTop_focused {
    position: absolute;
    z-index: 10;
    height: 80px;
    transform: translateY(-80px);
    background: #282828;
}

.searchTop_focused .searchTopCancel {
    width: auto;
    margin-left: 16px;
    opacity: 1;
}

.searchTopCancel {
    width: 0;
    opacity: 0;
    color: #c94141;
    cursor: pointer;
    transition: width .3s ease;
}

.searchBox {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    flex: 1;
}

.searchBox__icon {
    z-index: 2;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: transparent;
    fill: #fff;
}

.searchBox__input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 70px 0 39px;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    border-radius: 20px;
    background: #3e3e3e;
    border: 0;
    -webkit-box-shadow: 0 4px 8px rgb(0 0 0 / 2%);
    box-shadow: 0 4px 8px rgb(0 0 0 / 2%);
}