.requestItem {
  position: relative;
  display: block;
  margin-bottom: 16px;
  padding: 16px 50px 16px 16px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.12);
}

.requestItem:last-child {
  margin-bottom: 0;
}

.requestItem__top {
  display: flex;
  align-items: baseline;
  margin-bottom: 8px;
}
.requestItem__number {
  margin-right: 16px;
}
.requestItem__date {
  font-size: 14px;
  color: #999;
}

.requestItem__question {
  margin-bottom: 8px;
  padding-right: 16px;
  font-size: 18px;
  font-weight: 700;
  color: #9e2727;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.requestItem__last {
  font-weight: 400;
  font-style: italic;
  color: #999;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.requestItem .requestStatus {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 64px;
  text-align: center;
}
