.eventFilterTop {
  display: flex;
  align-items: center;
  padding: 16px 16px;
  background: #ededed;

  
}

.eventFilterTop label span {
  font-size: 13px;
}

.eventFilterTopStart {
  flex: 1;
}


.eventFilterToggleBtn {
  display: flex;
  align-items: center;
  stroke: #000;
  font-size: 13px;
  cursor: pointer;
}