.nav {
  position: fixed;
  bottom: 0;
  bottom: env(safe-area-inset-bottom);
  left: 50%;
  width: 100vw;
  max-width: 902px;
  transform: translate3d(-50%, 0, 1px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  border: 1px solid #d2d2d2;
  border-bottom: 0;
  background: #fff;
}

.app_isStandalone .nav {
  height: 80px;
  bottom: 0;
}

.app_isStandalone .nav__item {
  margin-bottom: 8px;
}

.nav__item {
  position: relative;
  display: block;
  flex: 1;
  text-align: center;
  text-decoration: none;
}

.nav__itemIcon {
  display: block;
  margin: 0 auto 5px;
  width: 28px;
  height: 28px;
  object-fit: contain;
}

.nav__itemTitle {
  display: block;
  font-size: 11px;
  line-height: 1.125;
  color: #949494;
}


.nav__item.main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav__item.main div {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 1px solid #d2d2d2;
  background: #fff;
}


.nav__item.active .nav__itemIcon {
  fill: #bc1212;
  filter: invert(13%) sepia(94%) saturate(4186%) hue-rotate(356deg) brightness(96%) contrast(96%);
}
.nav__item.active .nav__itemTitle {
  color: #bc1212;
}


.nav__itemCount {
  position: absolute;
  top: -6px;
  right: 9px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: 9px;
  font-weight: 700;
  line-height: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #7CCF78;
  color: #fff;
}
