.categorySlider {
  position: relative;
  padding: 16px 24px 0;
}

.categorySliderSlick {
  margin: 0 -8px;
}

.categorySliderSlick .slick-slide {
  padding: 4px;
}

.categoryCard {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  padding: 6px 12px;
  border-radius: 1em;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.categoryCard.active {
  background: #c94141;
  color: #fff;
  fill: #fff
}
.categoryCard.active .categoryCard__img {
  filter: brightness(0) invert(1);
}

.categoryCard__img {
  margin-right: 4px;
  width: 24px;
  height: 16px;
  object-fit: contain;
}

.categoryCard__title {
  white-space: nowrap;
  font-size: 14px;
}
