.propertyList {
    padding: 16px;
    background: #fff;
    border-bottom: .5px solid #737373;
}

.propertyListItem {
    display: flex;
    margin-bottom: 4px;
    font-size: 13px;
}

.propertyListItem:last-child {
    margin-bottom: 0;
}

.propertyListItem__lbl {
    position: relative;
    margin-right: 4px;
    font-weight: 700;
}

.propertyListItem__lbl.withIcon {
    padding-left: 20px
}

.propertyListItem__icon {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 18px;
    fill: #808080;
}

.propertyListItem__val {}

.propertyListItem__val__size_lg {
    font-size: 16px;
}


.propertyList.compact {
    padding: 0;
    border: 0;
}

.propertyList.compact .propertyListItem {
    display: block;
}

.propertyList.compact .propertyListItem__lbl {
    margin-bottom: 2px;
}

.propertyList.compact .propertyListItem__val {
    line-height: 1.125;
}