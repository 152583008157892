.topBar {
  position: sticky;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  padding: 0 16px 0;
  background: #282828;

  display: flex;
  align-items: center;
}

.topBar__title {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.backBtn {
  background: transparent;
  border: 0;
  padding: 0;
  margin-right: 16px;
  appearance: none;
}

.backBtn__icon {
  width: 24px;
  height: 24px;
  fill: #fff;
}


.topBar.mode_modal {
  background: transparent;
}