.codePassWrap {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    min-height: calc(100vh - 60px);
    background: #282828;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 24px 96px;
}

.codePassCard {
    position: relative;
    overflow: hidden;
    width: 100%;
    border-radius: 16px;
    background: #c94141;
    color: #fff;
    padding: 56px 24px 16px;
}

.codePassCard::before {
    content: '';
    position: absolute;
    top: -12%;
    right: -20%;
    width: 80%;
    height: 0;
    padding-bottom: 48%;
    background: url(/public/img/logo3000.png) 50% 50% no-repeat;
    background-size: contain;

    filter: brightness(0) invert(1);
    opacity: .4;
}

.codePassCard__title {
    margin-bottom: 8px;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
}

.codePassCard__name {
    margin-bottom: 24px;
    font-size: 18px;
    color: #000;
}

.codePassCard__grid {
    display: flex;
    margin: 0 -8px 24px;
}

.codePassCard__gridItem {
    flex: 1;
    padding: 0 8px;
}

.codePassCard__gridItemLbl {
    margin-bottom: 4px;
    font-size: 14px;
}

.codePassCard__gridItemVal {
    font-size: 14px;
    color: #000;
}


.codePassCard__qrTitle {
    margin-bottom: 8px;
    text-align: center;
}

.codePassCard__qr {
    position: relative;
    width: 100%;
    padding: 16px;
    border-radius: 16px;
    background: #fff;
}

.codePassCard__qr svg {
    width: 100%;
    height: 100%;
}