.searchBox {
  position: relative;
  z-index: 999999;
  transform: translateZ(1px);
  border-radius: 2em;
  background-color: #efefef;
}

.searchBox__btn {
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  appearance: none;
  border: 0;
  background: transparent;
  border-radius: 2em 0 0 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBox__btn .icon {
  width: 16px;
  height: 16px;
  fill: #959595;
}

.searchBox__inp {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 40px;
  padding-left: 40px;
  border: 1px solid #ddd;
  border-radius: 2em;
  background: #efefef;
}


.searchBox__suggestions {
  position: absolute;
  transform: translateZ(10000px);
  left: 0;
  right: 0;
  top: 0;
  border-radius: 20px;
  padding-top: 40px;
  background: #fff;
  box-shadow: 0px 1px 8px 0.2px rgba(0, 0, 0, 0.16);

  max-height: 600px;
  overflow-y: scroll;
}

.searchBox__loading {
  position: relative;
  padding: 16px;
}

.searchBox__loading .loaderWrap {
  width: 100%;
  height: 32px;
}

.searchBox__empty {
  padding: 16px;
}

.searchBox__suggestion {
  overflow: hidden;
}

.searchBox__suggestion:last-child {
  border-radius: 0 0 20px 20px;
}

.suggestionLink {
  display: flex;
  align-items: center;
  padding: 16px;
  color: #000;
}

.suggestionLink:hover, .suggestionLink:active {
  background: #fafafa;
}

.suggestionLink__icon {
  margin-right: 12px;
  width: 36px;
  height: 36px;
  padding: 4px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.suggestionLink__icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.suggestionLink__title {
  font-size: 15px;
  font-weight: 600;
}

.suggestionLink__note {
  font-size: 12px;
}