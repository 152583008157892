.tenantNav {
  display: flex;
  padding: 12px 0 0;
}

.tenantNav__start {
  flex: 1;
  display: flex;
}

.tenantNav__end {
  margin-left: 24px;
  display: flex;
  justify-content: flex-end;
}

.tenantNavLink {
  display: flex;
  align-items: center;
  margin-right: 16px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: #c94141;
  fill: #c94141;
}

.tenantNavLink:last-child {
  margin-right: 0;
}

.tenantNavLink.active {}

.tenantNavLink .icon {
  margin-right: 6px;
  font-size: 20px;
  fill: #c94141;
}

.tenantMapLink .icon {
  font-size: 20px;
}
