.error {
    margin: 0 16px 16px;
    padding: 8px 10px;
    font-size: 14px;
    text-align: center;
    border-radius: 8px;
    
    color: #c94141;
    background: rgba(201, 65, 65, 0.2);
    border: 1px solid #c94141;   
}

.error a {
    display: inline-block;
    border-bottom: 1px dashed #c94141;
}
