.schemePlaceEntrance {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    z-index: 10000;
    fill: rgba(202, 95, 81, 1);
}

.schemePlaceEntrance__icon.right {
    animation: rightPulse 1s infinite;
}

.schemePlaceEntrance__icon.left {
    animation: leftPulse 1s infinite;
}

.schemePlaceEntrance__icon.up {
    animation: upPulse 1s infinite;
}

.schemePlaceEntrance__icon.down {
    animation: downPulse 1s infinite;
}


@keyframes rightPulse {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-20%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes leftPulse {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(20%);
    }

    100% {
        transform: translateX(0);
    }
}


@keyframes upPulse {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(20%);
    }

    100% {
        transform: translateY(0);
    }
}


@keyframes downPulse {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20%);
    }

    100% {
        transform: translateY(0);
    }
}
