.header {
  position: sticky;
  top: 0;
  z-index: 2;
  height: 160px;
  background: #282828;
  overflow: hidden;
}

.header:after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translate(-50%, -60px);
  top: 20px;
  width: 186px;
  height: 110px;
  background: url(/public/img/logo3000_g.png) 50% 50% no-repeat;
  background-size: contain;
}


.header__title {
  position: absolute;
  bottom: 60px;
  left: 15px;
  right: 15px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}

.header_withBottom .header__title {
  /*bottom: 36px;*/
}


.header__top {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__topBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.header__top .icon {
  width: 30px;
  height: 30px;
  fill: #959595;
}


.header__bottomOld {
  position: absolute;
  bottom: -22px;
  left: 0;
  right: 0;
  height: 50px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__bottomBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin: 0 8px;

  background: #c94141;
}

.header__bottomBtn .icon {
  width: 20px;
  height: 20px;
  fill: #fff;
  object-fit: contain;
}


.header__bottom {
  position: absolute;
  bottom: 14px;
  left: 0;
  right: 0;
  height: 36px;
  padding: 0 16px;
  transition: all .3s ease;
}

.navBtn__icon {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 28px;
  border: 0;
}
.navBtn__icon span {
  position: relative;
  top: 13px;
  left: 2px;
  outline: 0!important;
}
.navBtn__icon span, .navBtn__icon span:after, .navBtn__icon span:before {
  display: block;
  background: #959595;
  width: 24px;
  height: 2px;
  border-radius: 2px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.navBtn__icon span:before {
  position: absolute;
  content: "";
  bottom: 7px;
}

.navBtn__icon span:after {
  position: absolute;
  content: "";
  top: 7px;
}

