.dateTimePicker {
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffc;
    border-top: 1px solid #dedede;
    border-radius: 16px 16px 0 0;
    background: #fff;
    overflow: hidden;

    transition: all .3s linear;
    transform: translateY(100%);
}

.dateTimePicker__title {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 36px 0 16px;
    font-size: 20px;
}

.dateTimePicker.show {
    transform: translate3d(0, 0, 1000px);
}

.dateTimePicker .Calendar {
    min-width: 100%;
    min-height: 34em;
    font-size: 10px;
    box-shadow: none;
    padding: 0;
}

.dateTimePicker .Calendar__sectionWrapper {
    position: relative;
    min-height: 24em;
    overflow: hidden;
}

.dateTimePicker .Calendar__section.-hiddenPrevious {
    opacity: 0.5;
    transform: translateX(-100%);
}

.dateTimePicker .Calendar__section.-hiddenNext {
    opacity: 0.5;
    transform: translateX(100%);
}

.dateTimePicker .Calendar__header {
    padding: 18px 22px;
}

.dateTimePicker .Calendar__weekDays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    font-size: 1.4em;
    margin: 8px 0 0;
    padding: 0 8px;
}

.dateTimePicker .Calendar__weekDay {
    display: block;
    margin: 0 auto;
    width: 2.38em;
    text-align: center;
    text-decoration: none;
}

.dateTimePicker .Calendar__section {
    padding: 0 8px;
    padding-top: 4px;
}

.dateTimePicker .Calendar__weekRow {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    width: 100%;
    margin-bottom: 8px;
}

.dateTimePicker .Calendar__day.-ltr {
    min-height: 2.28em;
    width: 2.28em;
    margin: 0 auto;
}


.dateTimePicker .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
    font-weight: 600;
    color: var(--cl-color-black);
    color: #000;
    position: relative;
    border-color: rgba(201, 65, 65, 0.3);
}

.dateTimePicker .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
    display: none;
}

.dateTimePicker .Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
    background: #eaeaea;
}


.timePicker {
    display: flex;
    flex-flow: column;
    width: 24vw;
    height: 26.6em;
    margin-top: 6em;
    padding: 0 0;
    font-size: 10px;
    overflow: hidden;
}

.timePicker__scroll {
    width: 25vw;
    padding-right: 1vw;
    flex: 1;
    overflow-y: scroll;
}

.timePicker__time {
    text-align: center;
    width: 80%;
    margin: 4px auto;
    padding: 4px 0;
    font-size: 1.4em;
    border-radius: 2em;
    border: 1px solid #eaeaea;
}

.timePicker__time.selected {
    background: #eaeaea;
}