.weekDates {
  display: flex;
  width: 100%;
  overflow: hidden;
  background: #bdbdbd;
}

.weekDates__prev,
.weekDates__next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
}

.weekDates__prev .icon,
.weekDates__next .icon {
  width: 20px;
  height: 20px;
  fill: #000;
  stroke: #000;
}

.weekDates__main {
  min-width: calc(100% - 56px);
  flex: 1;
  overflow: hidden;
}

.weekDates__inner {
  display: flex;
  flex-flow: row nowrap;
  transition: all .3s linear;
}

.weekDates__week {
  min-width: 100%;
  display: flex;
  padding: 4px 0 8px;
}

.weekDatesItem {
  display: block;
  flex: 1;
  text-align: center;
  line-height: 1.125;
  color: #000;
}

.weekDatesItem.active {
  color: #9e2727;
}

.weekDatesItem.active .weekDatesItem__day {
  background: #fff;
  border-color: #fff;
}

.weekDatesItem.current .weekDatesItem__day {
  border-color: #fff;
}

.weekDatesItem__day {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  border-radius: 50%;
  border: 1px solid transparent
}

.weekDatesItem__month {
  font-size: 12px;
}

.weekDatesItem__weekday {
  font-size: 12px;
}