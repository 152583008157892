.passCardGrid {
  display: flex;
  flex-flow: row wrap;
}

.passCardGrid .passCard {
  margin: 0 16px 16px 0;
  width: calc(50% - 8px);
}

.passCardGrid .passCard:nth-child(2n) {
  margin: 0 0 16px 0;
}

.passCard {
  position: relative;
  display: block;
  width: 136px;
  height: 136px;
  padding: 12px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.12);
  color: #383838;
  overflow: hidden;
}

.passCard__qricon {
  position: absolute;
  right: 12px;
  bottom: 12px;
  width: 50px;
  height: 50px;
  fill: #969696;
}

.passCard__icon {
  position: absolute;
  left: 0;
  bottom: 15px;
  width: 60px;
  height: 44px;
  object-fit: contain;
  fill: #969696;
}

.passCard__header {}

.passCard__title {
  margin-bottom: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 20px;
  line-height: 1.125;
  color: #9e2727;
}

.passCard__info {
  font-size: 12px;
  line-height: 1.125;
}


.passCard .loaderWrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .2);
}