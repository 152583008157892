.hallListItem {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 16px;
  overflow: hidden;
  color: #000;
  background: #fff;
  box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.12);
}

.hallListItem__body {
  min-width: 0;
}

.hallListItem__icon {
  margin-right: 16px;
  width: 40px;
  height: 40px;
  object-fit: cover;
}


.hallListItem__title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 18px;
  color: #9e2727;
}

.hallListItem__capacity {
  position: relative;
  font-size: 13px;
  line-height: 16px;
  padding-left: 24px;
}
.hallListItem__capacity:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  background: url(/public/img/icons/people.png) 50% 50% no-repeat;
  background-size: contain;
}
