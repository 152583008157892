.myPass {
  box-shadow: 0px 1px 8px 0.2px rgba(0, 0, 0, 0.16);
}

.newsSliderSlick {
  margin: 0 -8px;
}

.newsSliderSlick .slick-slide {
  padding: 8px;
}

.postCard {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 44%;
  border-radius: 16px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.12);
}

.postCard__img {
  width: 100%;
  object-fit: cover;
}

.postCard__body {
  position: absolute;
  z-index: 2;
  bottom: 30px;
  right: 0;
  max-width: 64%;
  padding: 4px 15px 4px 12px;
  background: #c94141;
  color: #fff;
}
.postCard__body:before {
  content: '';
  position: absolute;
  left: -6px;
  top: 0;
  bottom: 0;
  width: 12px;
  transform: skew(-20deg, 0);
  background: #c94141;
}


.postCard__title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
}
