.tariffDetail {
    padding-bottom: 80px;
}

.tariffDetailHeader {
    position: relative;
    padding: 16px;
}
.tariffDetailHeader__icon {
    position: absolute;
    top: -40px;
    left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #fff;
}
.tariffDetailHeader__icon img {
    width: 60%;
    height: 60%;
    object-fit: contain;
}
.tariffDetailHeader__title {
    margin-bottom: 8px;
    font-size: 20px;
    color: #9e2727;
}
.tariffDetailHeader__user {
    font-size: 15px;
}


.tariffDetailInfo {
    padding: 16px;
    background: #fff;
    border-bottom: .5px solid #737373;
}
.tariffDetailInfoItem {
    display: flex;
    margin-bottom: 8px;
    font-size: 13px;
}
.tariffDetailInfoItem__lbl {
    margin-right: 8px;
    font-weight: 700;
}
.tariffDetailInfoItem__lbl.capacity {
    position: relative;
    padding-left: 24px;
}
.tariffDetailInfoItem__lbl.capacity:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    background: url('/public/img/icons/people.png') 50% 50% no-repeat;
    background-size: contain;
}
.tariffDetailInfoItem__lbl.equipment {
    position: relative;
    padding-left: 24px;
}
.tariffDetailInfoItem__lbl.equipment:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    background: url('/public/img/icons/presentation.png') 50% 50% no-repeat;
    background-size: contain;
}
.tariffDetailInfoItem__val {

}

.tariffDetailText {
    padding: 20px 16px;
    background: #fff;
    border-bottom: .5px solid #737373;
    /*box-shadow: 0px 1px 8px 0.2px rgba(0, 0, 0, 0.16);*/
}


.tariffDetailActions {
    position: fixed;
    bottom: 60px;
    left: 0;
    width: 100vw;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
}
.app_isStandalone .tariffDetailActions {
    bottom: 80px;
}