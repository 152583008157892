/*! darkside3000 v1.0.0 | (c) 2022  | ISC License | git://bitbucket.com/zucker/fishing_html.git */
@font-face {
    font-family: 'PF DinDisplay Pro';
    src: local("PFDinDisplayPro-Regular"), url("/public/fonts/PFDinDisplayPro-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'PF DinDisplay Pro';
    src: local("PFDinDisplayPro-Light"), url("/public/fonts/PFDinDisplayPro-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'PF DinDisplay Pro';
    src: local("PFDinDisplayPro-Bold"), url("/public/fonts/PFDinDisplayPro-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'PF DinDisplay Pro';
    src: local("PFDinDisplayPro-Medium"), url("/public/fonts/PFDinDisplayPro-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
  }
  