.tenantDetailServices {
    margin-bottom: 16px;
    padding: 16px;
}

.tenantDetailServices__title {
    margin-bottom: 8px;
    font-size: 20px;
}

.tenantDetailServices__tags {
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
}
