.feedbackMessageWrap {
  padding-left: 32px;
}

.feedbackMessageWrap_isMy {
  padding-right: 32px;
  padding-left: 0;
}

.feedbackMessage {
  position: relative;
  display: block;
  margin-bottom: 16px;
  padding: 16px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.12);
}

.feedbackMessage__top {
  display: flex;
  align-items: baseline;
  margin-bottom: 8px;
}
.feedbackMessage__author {
  
}
.feedbackMessage__date {
  margin-right: 16px;
  font-size: 14px;
  color: #999;
}

.requestItem__question {
  margin-bottom: 8px;
  padding-right: 16px;
  font-size: 18px;
  font-weight: 700;
  color: #9e2727;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.requestItem__last {
  font-weight: 400;
  font-style: italic;
  color: #999;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.requestItem__status {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  text-align: center;
}
.requestItem__status.completed .requestItem__statusIcon1:after {
  content: '';
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  border-radius: 50%;
  border: 3px solid #000;
}
.requestItem__status.open {
  fill: #00B1F4;
  stroke: #00B1F4;
}
.requestItem__status.inProgress {
  fill: #FEA83C;
  stroke: #FEA83C;
}
.requestItem__status.completed {
  fill: #7CCF78;
  stroke: #7CCF78;
}
.requestItem__statusIcon {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 32px;
}
.requestItem__statusIcon .icon {
  position: relative;
  z-index: 2;
}
.requestItem__statusTitle {
  margin-top: 4px;
  font-size: 10px;
  white-space: nowrap;
}