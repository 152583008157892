.schemeNav {
    position: absolute;
    z-index: 3;
    left: 50%;
    top: 0;
    max-width: 600px;
    width: 100%;
    height: 50px;
    transform: translateX(-50%);
    padding: 0 20px;

    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
}

.schemeNav:before {
    content: '';
    position: absolute;
    height: 2px;
    left: 16px;
    right: 16px;
    top: 25px;
    transform: translateX(-1px);
    background: hsla(0,0%,89%,.35);
}

.schemeNav:after {
    content: '';
    position: absolute;
    top: 9px;
    height: 32px;
    left: 16px;
    right: 16px;
    border-radius: 2em;
    background: rgba(228, 228, 228, 0.5);
}


.schemeNavItem {
    position: relative;
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
    text-align: center;

    border-radius: 50%;
    color: #222;
    background: #e4e4e4;

    width: 24px;
    height: 24px;
    transition: all 0s linear;
}

.schemeNavItem.active {
    color: #fff;
    transition-duration: .5s;
}

.schemeNavItem:last-child {
    margin-right: 0;
}

.schemeNavItem div {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    transition: all .5s ease;
}


.schemeNavDot {
    position: absolute;
    z-index: 1;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #c94141;
    bottom: 0;
    top: 50%;
    transform: translate(0, -50%);


    transition: all .5s;
    /*animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: both;*/
}

.schemeNavDot.animate {
    animation-name: dot-scale;
}


@keyframes dot-scale {
    0% {
        width: 24px
    }

    50% {
        width: 20px
    }

    100% {
        width: 24px
    }
}