.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  background: #9e2727;
  border: 1px solid transparent;
  color: #fff;
  border-radius: 8px;
  height: 40px;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 700;
  user-select: none;
}

.btn_size_lg {
  height: 50px;
  padding: 12px 24px;
  font-size: 15px;
}

.btn_loading {
  position: relative;
}

.btn_loading .loaderWrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
  background: #9e2727;
}


.btn__icon {
  margin-right: 8px;
  fill: #fff;
}


.btnBorder {
  background: transparent;
  border: 1px solid #9e2727;
  color: #9e2727;
}

.btnBorder .btn__icon {
  fill: #9e2727;
}