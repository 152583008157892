.accessItem {
  position: relative;
  display: flex;
  margin-bottom: 16px;
  padding: 16px 50px 16px 16px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.12);
}

.accessItem:last-child {
  margin-bottom: 0;
}

.accessItem__icon {
  width: 60px;
  height: 60px;
  margin-right: 16px;
  fill: #808080;
}

.accessItem__top {
  display: flex;
  align-items: baseline;
  margin-bottom: 8px;
}
.accessItem__number {
  margin-right: 16px;
}
.accessItem__date {
  font-size: 14px;
  color: #999;
}

.accessItem__question {
  margin-bottom: 8px;
  padding-right: 16px;
  font-size: 18px;
  font-weight: 700;
  color: #9e2727;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accessItem__last {
  font-weight: 400;
  font-style: italic;
  color: #999;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accessItem .accessStatus {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 64px;
  text-align: center;
}
