.cardLp {
    padding-bottom: 40px;
}

.cardLp__btns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
}

.cardLp__h1 {
    margin-bottom: 16px;
    padding: 0;
    font-family: 'PF DinDisplay Pro';
    font-size: 24px;
    font-weight: 700;
    line-height: 1.375;
}

.cardLp__text {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.375;
}