.eventList {
  padding-bottom: 80px;
}

.eventListItem {
  display: flex;
  color: #000;
  background: #fff;
  border-bottom: 0.5px solid #aeaeae;
}

.eventListItem.free .eventListItem__name {
  color: #797979;
}

.eventListItem__time {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  padding: 12px 8px;
  border-right: 0.5px solid #aeaeae;
  font-size: 13px;
}

.eventListItem__body {
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 12px 8px;
}


.eventListItem__name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  color: #9e2727;
  margin-bottom: 2px;
}

.eventListItem__hall {
  position: relative;
  font-size: 12px;
  line-height: 16px;
}

.eventListItem__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
}
.eventListItem__icon .icon {
  width: 16px;
  height: 16px;
  stroke: #a7a7a7;
}
