.tenantCard {
  position: relative;
  display: block;
  padding: 16px;
  border-radius: 16px;
  overflow: hidden;
  color: #000;
  background: #fff;
  box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.12);
}

.tenantCard:after {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  height: 32px;
  border-radius: 0 0 16px 16px;
  background: linear-gradient(0deg, #fff 30%, transparent 100%);
}

.tenantCard__logo {
  display: block;
  width: 100%;
  height: 40px;
  object-fit: contain;
}

.tenantCard__body {
  margin-top: 16px;
}

.tenantCard__title {
  height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 1.25;
  text-align: center;
}

.tenantCard_emptyLogo {
  display: flex;
  align-items: center;
}
.tenantCard_emptyLogo .tenantCard__body {
  margin-top: 0;
  width: 100%;
}
.tenantCard_emptyLogo .tenantCard__title {
  font-size: 16px;
  height: auto;
}