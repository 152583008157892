.loginPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
}

.loginPanel {
    width: 100%;
    max-width: 400px;
    padding: 24px;
}

.loginPageBack {
    position: absolute;
    z-index: 2;
    top: 24px;
    left: 24px;
    font-size: 24px;
    stroke: #000;
}

.loginPageClose {
    position: absolute;
    z-index: 2;
    top: 24px;
    right: 24px;
    font-size: 24px;
}


.loginPageLoader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh + 60px);
    background: rgba(0, 0, 0, .5);

    display: flex;
    align-items: center;
    justify-content: center;
}

.loginPageLoader .loaderWrap {
    width: 100%;
    top: -30px;
}
