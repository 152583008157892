.schemePlaceInfo {
    position: absolute;
    z-index: 2;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.schemePlaceInfo__inner {
    position: relative;
    /*max-width: 80%;*/
    margin: 0 auto;
    transform-origin: 50% 50%;
}
.schemePlaceInfo__inner:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
}

.isUsed .schemePlaceInfo .schemePlaceInfo__title {
    font-weight: 700;
}

.schemePlaceInfo__title {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    min-height: 10px;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    line-height: 1.125;
    color: #000;
}
.schemePlaceInfo__titleSub {
    margin-top: .5em;
    text-transform: uppercase;
    font-size: 1.25em;
}

.schemePlaceInfo__logo {
    width: 80px;
    margin: 0 auto 8px;
    text-align: center;
}
.schemePlaceInfo__logo img {
    width: 100%;
    object-fit: contain;
}
