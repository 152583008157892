.checkboxField {}

input[type=checkbox] {
  appearance: none;
  background-color: #f8f8f8;
  margin: 0;
  padding: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border-radius: 4px;
  border: 1px solid #ddd;
  outline: 0;
  box-shadow: none;

  display: grid;
  place-content: center;
}

input[type=checkbox]:focus {
  appearance: none;
  outline: none !important;
  border-color: transparent;
  box-shadow: 0 0 3px #c94141;
}

input[type="checkbox"]::before {
  content: "";
  position: relative;
  top: -.3em;
  left: .2em;
  width: 1em;
  height: .5em;
  border: 2px solid #000;
  border-top: none;
  border-right: none;

  opacity: 0;
  transform: rotate(-48deg);
  transition: 120ms transform ease-in-out;
}

input[type="checkbox"]:checked::before {
  opacity: 1 !important;
}

.field.checkboxField label, .checkboxField label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-weight: 400;
}

.checkboxField label span {
  margin-left: 8px;
}