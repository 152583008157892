.loaderWrap {
  position: relative;
}

.loaderWrap.active:before {
  content: '';
  position: absolute;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .15);
  border-radius: 8px;
}
.loaderWrap.dark:before {
  background: transparent;
}

.loaderWrap.active .loader {
  display: flex;
}

.loader {
  position: absolute;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: #ffffff;
}
.loaderWrap.dark .loader {
  color: #000;
}

.loader__inner {
  position: relative;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  display: block;
  color: #fff;
  left: -10em;
  box-sizing: border-box;
  animation: shadowRolling 3s linear infinite;
}
.loaderWrap.dark .loader__inner {
  color: #000;
  animation: shadowRollingDark 3s linear infinite;
}

@keyframes shadowRolling {
  0% {
    box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  12% {
    box-shadow: 10em 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  25% {
    box-shadow: 11em 0 white, 10em 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  36% {
    box-shadow: 12em 0 white, 11em 0 white, 10em 0 white, 0px 0 rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 13em 0 white, 12em 0 white, 11em 0 white, 10em 0 white;
  }
  62% {
    box-shadow: 20em 0 rgba(255, 255, 255, 0), 13em 0 white, 12em 0 white, 11em 0 white;
  }
  75% {
    box-shadow: 20em 0 rgba(255, 255, 255, 0), 20em 0 rgba(255, 255, 255, 0), 13em 0 white, 12em 0 white;
  }
  87% {
    box-shadow: 20em 0 rgba(255, 255, 255, 0), 20em 0 rgba(255, 255, 255, 0), 20em 0 rgba(255, 255, 255, 0), 13em 0 white;
  }
  100% {
    box-shadow: 20em 0 rgba(255, 255, 255, 0), 20em 0 rgba(255, 255, 255, 0), 20em 0 rgba(255, 255, 255, 0), 20em 0 rgba(255, 255, 255, 0);
  }
}


@keyframes shadowRollingDark {
  0% {
    box-shadow: 0px 0 rgba(0, 0, 0, 0), 0px 0 rgba(0, 0, 0, 0), 0px 0 rgba(0, 0, 0, 0), 0px 0 rgba(0, 0, 0, 0);
  }
  12% {
    box-shadow: 10em 0 #000, 0px 0 rgba(0, 0, 0, 0), 0px 0 rgba(0, 0, 0, 0), 0px 0 rgba(0, 0, 0, 0);
  }
  25% {
    box-shadow: 11em 0 #000, 10em 0 #000, 0px 0 rgba(0, 0, 0, 0), 0px 0 rgba(0, 0, 0, 0);
  }
  36% {
    box-shadow: 12em 0 #000, 11em 0 #000, 10em 0 #000, 0px 0 rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 13em 0 #000, 12em 0 #000, 11em 0 #000, 10em 0 #000;
  }
  62% {
    box-shadow: 20em 0 rgba(0, 0, 0, 0), 13em 0 #000, 12em 0 #000, 11em 0 #000;
  }
  75% {
    box-shadow: 20em 0 rgba(0, 0, 0, 0), 20em 0 rgba(0, 0, 0, 0), 13em 0 #000, 12em 0 #000;
  }
  87% {
    box-shadow: 20em 0 rgba(0, 0, 0, 0), 20em 0 rgba(0, 0, 0, 0), 20em 0 rgba(0, 0, 0, 0), 13em 0 #000;
  }
  100% {
    box-shadow: 20em 0 rgba(0, 0, 0, 0), 20em 0 rgba(0, 0, 0, 0), 20em 0 rgba(0, 0, 0, 0), 20em 0 rgba(0, 0, 0, 0);
  }
}
