.pathInfo {
    padding: 16px 0;
}

.pathInfo__title {
    position: relative;
    top: -1px;
    margin-bottom: 16px;
    font-size: 20px;
}

.pathInfo__text {}

.pathInfo__text img {
    max-width: 100%;
}

.pathInfo__text ul,
.pathInfo__text ol {
    padding: 0 0 0 1em;
    margin: 0;
}
.pathInfo__text li {
    margin-bottom: .5em;
}
