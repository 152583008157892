@import "./fonts.css";

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'PT Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #383838;
  -webkit-tap-highlight-color: transparent;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

body.fixed {
  overflow: hidden;
}

#root {
  max-width: 900px;
  margin: 0 auto;
}

a {
  text-decoration: none;
  color: #c94141;
}

input:focus,
textarea:focus,
select:focus {
  appearance: none;
  outline: none !important;
  border-color: transparent;
  box-shadow: 0 0 6px #c94141;
}

p {
  margin: 0 0 1rem;
}


.app_isModal .page {}

.app_isModal .page:after {
  content: '';
  position: fixed;
  z-index: 80;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transform: translateZ(1px);
  background: rgba(40, 40, 40, .75);
}


.field {
  position: relative;
}

.field_loading {
  opacity: .7;
}

.field_loading:after {
  content: '';
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
}

.field__error {
  padding-top: 4px;
  font-style: italic;
  font-size: 14px;
  line-height: 1.125;
  color: #c94141;
}

.field__help {
  padding-top: 4px;
  font-style: italic;
  font-size: 14px;
  line-height: 1.125;
  color: #676767;
}

.field label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
}

.field label abbr {
  position: relative;
  top: 3px;
  display: inline-block;
  padding: 0 2px;
  text-decoration: none;
  color: #c94141;
}


.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #000;
}

.slick-prev:before {
  content: '〈';
}

.slick-next:before {
  content: '〉';
}


@import "~react-image-gallery/styles/css/image-gallery.css";
@import '@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css';