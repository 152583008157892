.placeDetail {
    padding: 0 0 16px;
}

.placeDetail__number {
    font-size: 12px;
    color: #000;
}

.placeDetail__main {
    display: flex;
    padding-top: 16px;
}

.placeDetail__bottom {
    display: flex;
    margin-top: 8px;
}

.placeDetail__bottom .btn {
    margin-right: 16px;
    width: 100%;
    justify-content: center;
}
.placeDetail__bottom .btn:last-child {
    margin-right: 0;
}

.placePic {
    margin-right: 16px;
    min-width: 120px;
    max-width: 120px;
}

.placePic img {
    max-width: 100%;
}

.placeDetail__text {
    margin-bottom: 8px;
    font-size: 12px;
}


.placeTitle {
    display: flex;
    align-items: center;  
}
.placeTitle span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.placeStatus {
    display: flex;
    align-items: center;
    margin-left: 16px;
    padding: 0 12px 2px;
    height: 20px;
    font-size: 12px;
    line-height: 1.125;
    color: #666;
    border: 1px solid #ddd;
    background: rgba(221, 221, 221, .25);
    border-radius: 2em;
}

.placeStatus.isBusy {
    color: #ca5f51;
    border-color: rgba(202, 95, 81, 0.5);
    background: rgba(202, 95, 81, 0.25);
}

.placeStatus.isFree {
    color: rgb(91, 129, 87);
    border-color: rgba(145, 203, 138, 0.5);
    background: rgba(145, 203, 138, 0.25);
}
